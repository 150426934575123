/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  MouseEventHandler,
  ReactNode,
} from 'react';

import { ThemeBorderRadius } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { BadgeType, Container, Wrapper } from './styles';

interface BadgeProps {
  type?: BadgeType;
  target?: string;
  borderRadius?: ThemeBorderRadius;
  style?: CSSProperties;
  tooltip?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  isDisabled?: boolean;
  readonly showTooltip?: boolean;
  readonly onMouseLeave?: () => void;
  href?: string;
}

const BaseBadge: ForwardRefRenderFunction<HTMLDivElement, BadgeProps> = (
  {
    children,
    borderRadius = 'lg',
    type = 'primary',
    tooltip,
    style,
    onClick,
    isDisabled,
    showTooltip = undefined,
    href,
    target,
  },
  ref
) => {
  return (
    <Tooltip
      label={tooltip}
      hasArrow
      closeOnClick={false}
      isOpen={showTooltip}
      closeDelay={0.3}
    >
      <Wrapper style={style} {...(!!href && { as: 'a', href, target })}>
        <Container
          isDisabled={isDisabled}
          borderRadius={borderRadius}
          type={type}
          className="badge"
          href={href}
          ref={ref}
          onClick={onClick}
        >
          <div>{children}</div>
        </Container>
      </Wrapper>
    </Tooltip>
  );
};

export const Badge = forwardRef(BaseBadge);

export const permissions = {
  dashboard: {
    list: '61b498a9-9c87-42f6-be70-37440655501e',
  },
  companies: {
    list: '96e3474f-61f8-4ee9-b40d-b5dbdd7012fb',
    create: '34943c3a-c0c1-428d-a607-8e35c93399c5',
    update: 'e08d9d64-b5c8-4521-9d8c-7f910a358d7a',
    delete: 'fe754034-4c30-49d2-81f8-d7978440cad0',
  },
  groups: {
    list: '5bd3b47a-a376-4bed-a116-0593aba6bc71',
    create: 'dc46f559-1265-49f0-bf19-c3b9666312c4',
    update: 'dc46f559-1265-49f0-bf19-c3b9666312c4',
    delete: '86eac130-149d-4f25-a020-5a1e024dbcb7',
  },
  categories: {
    list: '31175f45-1087-49cf-b745-5052ff48ec1b',
    create: '21f1edd9-8368-40bb-8a78-4aedd99fb36b',
    update: '53f62d40-5e1d-47ed-80cc-7b04e413c12b',
    delete: '0f62dd47-e3fd-4d13-9d7f-358d826d17df',
  },
  catalogs: {
    list: '8d730f92-3eab-4a44-a9bf-eeb74e42fd21',
    create: '74969a84-986f-48ea-b754-134c411ca316',
    update: '1e4e8d1b-b315-403d-88d3-b1c45bf84f51',
  },
  products: {
    list: 'c01e7a95-036b-4004-b7d6-705631757bd5',
    create: 'b253e351-0aea-4097-87cc-1e1025e4941d',
    update: '2168e79d-3841-464e-8651-90d85e688092',
    delete: '887d82aa-c7e6-4cee-8ab8-de7a7ba13e98',
  },
  customFields: {
    list: '03ac99ea-148b-4c33-b29c-d6449738ff44',
    create: 'f8c32182-838c-4b39-8477-336a639a59c0',
    update: '9cb519e6-62f4-4aee-891e-9a04f7ce22d3',
  },
  orders: {
    list: 'e090215c-1a51-4b68-aef0-f29d4205a28c',
    cancel: 'cc9b0ba4-75a5-48bb-a829-114b37216a7d',
  },
  transactions: {
    create: 'd7ba5ba5-3d34-4090-a4d3-d3eeb0577d83',
  },
  users: {
    list: 'ec34b55e-9d67-420d-8a33-9acc6eda2299',
    create: 'e739ad2e-fb10-4146-b452-bfd809a98511',
    update: '46e3a570-39f6-4331-ad24-5213b51e7cde',
    delete: 'b14fd4ca-5414-477e-ab67-e456d6be1a7b',
  },
  cashiers: {
    list: '044d45cc-8214-482e-b5c4-719afeaf4ff7',
  },
  collectsControl: {
    list: '0365b675-67a3-416b-8646-44e082112e16',
    depoist: '42099770-b6d0-4c2c-8409-1b65aaf280f2',
  },
  events: {
    list: '7c62ac07-7328-4335-8ecd-a2b8c8822b64',
    create: '1ab97b0a-df5b-4de2-8627-ac0e4583a9c2',
    update: '8fd53631-15d5-47c5-84ec-8bd12f3536a7',
    delete: 'a5491b8a-76de-4527-b90b-5e1519a01db3',
  },
  customers: {
    list: '08368e86-8c40-446d-90b3-9da57b9a2677',
    create: '16932bc8-f479-41c4-9818-f65a0d68636b',
    update: '26a1f907-6649-464b-8678-04454cb7a2d5',
    delete: 'a9e7d4bd-df02-4733-86a8-adbf935942ee',
  },
  sellers: {
    list: '39cf075b-959a-4927-8514-55ba73f671f7',
    create: 'aa377371-74f7-477b-9e43-30229f571e5e',
    update: 'b160c65d-606d-4c7c-aa5f-9b6cf61c1cdf',
    delete: '077e958a-f412-4334-9e5c-dea91f7968b4',
  },
  account: {
    list: 'ca125acf-de26-4c14-9ef4-0998f28f2aa6',
    create: '072ca2e0-e518-44b1-8430-50eee5dce432',
    update: '33fdb5e7-74b7-4a5f-87d9-c1cd893919bf',
    delete: '8bfb6bb0-3de3-47ee-87a3-250c52ebb45d',
  },
  taxationRules: {
    list: '0bd45f64-a496-4fdd-a8f6-dda91885332c',
    create: 'b1d5b321-be0c-44ee-85ae-06dddb8db414',
    update: '936a42cd-a9d4-4b75-8cc7-285a6b6cc662',
    delete: '936a42cd-a9d4-4b75-8cc7-285a6b6cc662',
  },
  subscriptions: {
    list: '4e11eec5-2547-4390-97e9-9c70e81a1a1c',
    update: 'f04c6380-e8bf-42ed-9785-23e61404563d',
  },
};
